import axios from './axios'


//登陆 获取token
export function toLogin(clientId,secret) {
  return axios({
    url: 'token',
    method:'post',
    data:{
        clientId,
        secret,
        clientType: 'user',
        requiredUser:true
    }
  })
}
//权限验证 
//Headquarters总部   distributor经销商老板    salesman 业务员
export function validRole(){
    return axios({
        url:'/api/permission/validRole'
    })
}