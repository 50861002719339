import originAxios from 'axios'
import qs from 'qs'


export default function axios(option) {
	return new Promise((resolve, reject) => {
		// 1.创建axios的实例
		const instance = originAxios.create({
			// baseURL:'https://power.smegold.com/admin/',
			// baseURL:'https://power-t.smegold.com/admin/',
			 baseURL:'/admin/',
			//baseURL:'',
			timeout: 5000,
		});
		// 配置请求和响应拦截
		instance.interceptors.request.use(config => {
			let token = sessionStorage.getItem('token');
				if(token){
					config.headers['x-auth-token'] = token;
				}
				
			 config.data = qs.stringify(config.data);
			 return config;
		}, err => {
			return err
		})
		// axios.defa
		instance.interceptors.response.use(response => {
			return response.data
		}, err => {
			if (err && err.response) {
				switch (err.response.status) {
					case 400:
						err.message = '请求错误'
							break
					case 401:
						err.message = '未授权的访问'
							break
					}
				}
			return err
		})
		instance(option).then(res => {
			resolve(res)
		}).catch(err => {
			reject(err)
		})
	})
}