<template>
     <TabBar>
        <TabBarItem link="/customerList">
            <img slot="icon" src="../../assets/image/tabBar/index.png" alt="">
            <img slot="active-icon" src="../../assets/image/tabBar/index-select.png" alt="">
            <div slot="text">首页</div>
        </TabBarItem>
         <!-- <TabBarItem link="/powerMeasure">
             <img slot="icon" src="../../assets/image/tabBar/measure.png" alt="">
            <img slot="active-icon" src="../../assets/image/tabBar/measure-select.png" alt=""> 
            <div slot="text">电费测算</div> 
        </TabBarItem> -->
        <TabBarItem link="/main">
            <img slot="icon" src="../../assets/image/tabBar/main.png" alt="">
            <img slot="active-icon" src="../../assets/image/tabBar/main-select.png" alt="">
            <div slot="text">我的</div>
        </TabBarItem> 
    </TabBar> 
    
</template>

<script>
import TabBar from '../tabBar/TabBar.vue'
 import TabBarItem from '../tabBar/TabBarItem.vue'
 export default{
        name:'MainTabBar',
        components:{
            TabBar,
          TabBarItem
        }
    }
</script>

