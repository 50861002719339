
import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from  '../views/login/Login.vue'

const CustomerList = () => import('../views/customerList/CustomerList.vue')
const BasicMessage = () => import('../views/basicMessage/BasicMessage.vue')
const UploderList = () => import('../views/uploder/UploderList.vue')
const PowerMeasure = () => import('../views/powerMeasure/powerMeasure.vue')
const Main = ()=>import('../views/main/Main.vue')
const UploderDetail = ()=> import('../views/uploder/UploderDetail.vue')
const UploderCheck = ()=> import('../views/check/UploderCheck.vue')
const UploderElectricityPlan = ()=> import('../views/electricityPlan/UploderElectricityPlan.vue')
//const DetailMeasure = ()=> import('../views/powerMeasure/detailMeasure/DetailMeasure.vue')
const PowerTest =()=> import('../views/powerMeasure/detailMeasure/powerComponent/PowerTest.vue')
const MeasureConclusion =()=> import("../views/basicMessage/MeasureConclusion/measureConclusion.vue")
const PlanExplain =()=> import("../views/powerMeasure/detailMeasure/powerComponent/PlanExplain.vue")
const CustomerSumit =()=>import("../views/basicMessage/CustomerSumit.vue")
const MeasureContract =()=>import("../views/powerMeasure/detailMeasure/MeasureContract.vue")
const ElectricityBplan =()=>import("../views/electricityPlan/electricityBplan.vue")
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/customerList'
  },
  {
    path: '/customerList',
    name: 'CustomerList',
    component: CustomerList
  },
  {
    path:'/login',
    name:'Login',
    component:Login
  },
  {
    path: '/basicMessage',
    name: 'BasicMessage',
    component: BasicMessage,
    children:[
      {
        path:'uploderList',
        name:'UploderList',
        component:UploderList,
        children:[
          {
            path:'uploderDetail',
            name:'UploderDetail',
            component:UploderDetail,
          }
        ]
      },
      {
        path:'uploderCheck',
        name:'UploderCheck',
        component:UploderCheck,
      },
      {
        path:'uploderElectricityPlan',
        name:'UploderElectricityPlan',
        component:UploderElectricityPlan,
      },
      
      {
        path:'measureConclusion',
        name:'MeasureConclusion',
        component:MeasureConclusion,
      },
      {
        path:'measureContract',
        name:'MeasureContract',
        component:MeasureContract,
      },
    ]
  },
  {
    path: '/powerMeasure',
    name: 'PowerMeasure',
    component: PowerMeasure,
    children:[
        {
          path:'powerTest',
          name:'PowerTest',
          component:PowerTest,
          children:[
            {
              path:'planExplain',
              name:'PlanExplain',
              component:PlanExplain,
            },
            {
          
              path:'electricityBplan',
              name:'electricityBplan',
              component:ElectricityBplan,
            
            }
           ]
        },
        {
          path: 'basicMessage',
          name: 'BasicMessage',
          component: BasicMessage,
          children:[
            {
              path:'uploderList',
              name:'UploderList',
              component:UploderList,
              children:[
                {
                  path:'uploderDetail',
                  name:'UploderDetail',
                  component:UploderDetail,
                }
              ]
            },
            {
              path:'uploderCheck',
              name:'UploderCheck',
              component:UploderCheck,
            },
            {
              path:'customerSumit',
              name:'CustomerSumit',
              component:CustomerSumit,
            },
            {
              path:'uploderElectricityPlan',
              name:'UploderElectricityPlan',
              component:UploderElectricityPlan,
            },
            
            {
              path:'measureConclusion',
              name:'MeasureConclusion',
              component:MeasureConclusion,
            },
           
          
          ]
        }
    
    ]
  },
  {
    path: '/main',
    name: 'Main',
    component: Main,
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

router.beforeEach((to, from, next) => {
console.log(to)

  let token = sessionStorage.getItem('token')
  
  if(token){
    next();
  }else{
  
    //如果是登录页面路径，就直接next()
    if (to.path === '/login' || to.path.includes('powerMeasure')) {
      next();
    } else {
      next('/login');
    }
  }
})

export default router
