<template>
    <div>
        <div class="logo">
            <!-- <img src="../../assets/image/login/wlLogo.png" /> -->
            <img :src="require('../../assets/image/login/'+imgName)" />
        </div>
        <van-tabs v-model="active">
            <van-tab title="用户">
                <div class="accountDiv">
                    <img src="../../assets/image/login/account.png" alt="">
                    <input type="text" placeholder="请输入联系人姓名" v-model="name">
                </div>
                <div class="codeDiv">
                    <img src="../../assets/image/login/phone.png" alt="">
                    <input type="number" placeholder="请输入联系人手机号" v-model="phone" >
                </div>
                <div class="toLogin" @click="customerLogin">
                    登录
                </div>
            </van-tab>
            <van-tab :title="titleName">
                <div class="accountDiv">
                    <img src="../../assets/image/login/account.png" alt="">
                    <input type="text" placeholder="请输入账号" v-model="account">
                </div>
                <div class="codeDiv">
                    <img src="../../assets/image/login/code.png" alt="">
                    <input type="password" placeholder="请输入密码" v-model="code" ref="code">
                    <img :src="require('../../assets/image/login/'+eyesImg)" ref="codeImg" class="eyes" @click="hiddenPwd">
                </div>
                <div class="toLogin" @click="goToLogin">
                    登录
                </div>
            </van-tab>    
        </van-tabs>
      

        <div class="bottomDiv" v-show="hidshow||!isAndroid">
            <!-- <img src="../../assets/image/login/bottomLogo.png" alt=""> -->
            ——  版权归融砚委服公司所有  ——
        </div>
    </div>
</template>
<script>
import {toLogin,validRole} from '../../network/login'
import {getInternational} from '../../network/addMes'
    export default{
        name:'Login',
        data(){
            return{
                name:'',
                phone:'',
                account:'',
                code:'',
                docmHeight: '0', //初始状态可视区高度
                showHeight: '0', //实时可视区高度
                hidshow: true, //是否显示底部
                isAndroid: false, //是否为安卓系统
                eyesImg:'eyes.png',
                active:0,
                titleName:'经销商',
                imgName:'lm_db.png'
            }
        },
        methods:{
            customerLogin(){
                if(this.name.length == 0){
                       this.$toast.fail('请输入姓名');
                        return
                }
                if(this.phone.length == 0){
                    this.$toast.fail('请输入手机号');
                        return
                }
                this.$toast.loading({
                    message: '努力加载中...',
                    forbidClick: false,
                    duration:0
                });
                  toLogin(this.phone,'123456').then(res=>{
                     this.$toast.clear();
                    if(res.success){
                            sessionStorage.setItem('token',res.data);
                             sessionStorage.setItem('userId',res.extra.userId);
                            validRole().then((res)=>{
                                //Headquarters总部 distributor经销商老板 salesman 业务员 customer客户
                                    if(res.success){
                                        sessionStorage.setItem('identity',res.data);
                                        this.$router.replace('/customerList')
                                    }else{
                                        this.$toast.fail(res.msg);
                                    }
                               }) 
                            
                    }else{
                        this.$toast.fail(res.msg);
                    }
                })
            },
            goToLogin(){
                
                if(this.account.length == 0){
                       this.$toast.fail('请输入账号');
                        return
                }
                if(this.code.length == 0){
                    this.$toast.fail('请输入密码');
                        return
                }
                this.$toast.loading({
                    message: '努力加载中...',
                    forbidClick: false,
                    duration:0
                });
                
                  toLogin(this.account,this.code).then(res=>{
                     this.$toast.clear();
                    if(res.success){
                            sessionStorage.setItem('token',res.data);
                            sessionStorage.setItem('userId',res.extra.userId);
                            sessionStorage.setItem('userName',res.extra.user.name);
                            sessionStorage.setItem('orgName',res.extra.user.orgName);
                            validRole().then((res)=>{
                                //Headquarters总部 distributor经销商老板 salesman 业务员 customer客户
                                    if(res.success){
                                        sessionStorage.setItem('identity',res.data);
                                        this.$router.replace('/customerList')
                                    }else{
                                        this.$toast.fail(res.msg);
                                    }
                               }) 
                            
                    }else{
                        this.$toast.fail(res.msg);
                    }
                })
            },
            hiddenPwd(){
                let type = this.$refs.code.getAttribute('type')
                
                if(type == 'password'){
                    
                    this.$refs.code.setAttribute('type','text'); 
                   this.eyesImg = 'openEyes.png';
                }else{ 
                    this.$refs.code.setAttribute('type','password');
                    this.eyesImg = 'eyes.png';
                }
             },
             isAndroidMobile() {
                    var u = navigator.userAgent;
                    var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
                    return isAndroid;
            },
            getLogoImg(){
                let url = 'https://power.smegold.com/admin/powerImg/';
              //  let type = this.$route.query.type;
                let imgName = this.$route.query.imgName;
                return require(url+imgName);
            }
             
        },
        created () {
            //仅针对安卓做处理，不然的话ios会出现新的问题
            this.isAndroid = this.isAndroidMobile();
            this.docmHeight = document.documentElement.clientHeight;
               // 直销DS 经销商DB 银行DH
               let type = this.$route.query.type;
               let imgName = this.$route.query.imgName;
               if(imgName){
                this.imgName = imgName;
               }
               
                getInternational(type).then((res)=>{
                    if(res.success){
                        sessionStorage.setItem('enName',res.data.enName)
                        sessionStorage.setItem('enPhone',res.data.enPhone)
                        sessionStorage.setItem('usName',res.data.usName)
                        sessionStorage.setItem('usPhone',res.data.usPhone)
                    }
                })
                switch (type) {
                    case 'DS':
                        this.titleName = '宁丰电力'
                        break;
                    case 'DB':
                        this.titleName = '经销商'
                        break;
                    case 'DH':
                        this.titleName = '银行'
                        break;    
                    default:
                        break;
                }
        },

        mounted(){
            document.querySelector('body').setAttribute('style','background-color:#ffffff');
            window.onresize = () => {
                return (() => {
                    this.showHeight = document.body.clientHeight
                    if (this.docmHeight > this.showHeight) {
                        this.hidshow = false
                    } else {
                        this.hidshow = true
                    }
                })()
            }
         

        }

        
    }
</script>
<style scoped>
.logo img{
    width:100%;
}
.logo{
    margin-bottom: 80px;
}
.accountDiv,.codeDiv{
    border-bottom: 2px #F2F4F6 solid;
    height: 109px;
    width: 75%;
    margin:0 auto;
    display: flex;
    align-items: center;
    position: relative;
}
.accountDiv img{
    width: 35px;
    margin-right:25px;
}
.codeDiv img{
    width:28px; 
    margin-right:25px;
}
.toLogin{
        line-height: 88px;
        background: linear-gradient(-20deg, #11B9BE 0%, #3CC3E0 100%);
        border-radius: 44px;
        width: 84%;
        margin:85px auto;
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
  
}
.eyes{
    position: absolute;
    width: 32px;
    right: 25px;
}
.bottomDiv{
    width: 100%;
        text-align: center;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #A1A1A1;
        bottom: 27px;
        position: fixed;
}
.bottomDiv img{
    width: 113px;
}
</style>
<style lang="less" scoped>
:deep(.van-tab){
    font-size: 38px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #818080;
    line-height: 55px;
}
:deep(.van-tab--active){
    font-size: 40px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #06B2B2;
    line-height: 55px;
}
:deep(.van-tabs__line){
    background-color:#06B2B2;
}

</style>
<!-- 传参
type
直销  DS  经销商DB  银行  DH
imgName
温岭 wl_dh.png 
柯成 kc_dh.png
甬城 yc_dh.png
宁丰电力 nf_ds.png
经销商 lm_db.png
稠州银行 ch_dh.png
融愿 ry_ds.png

测算
传参
type 
直销  DS  经销商DB  银行  DH
titleName -->