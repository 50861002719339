import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import { Toast,NavBar,Uploader,DatetimePicker,Popup,Collapse, CollapseItem,Tab, Tabs,Popover,PullRefresh,List,Field,Form,Overlay,Picker,Dialog,Step, Steps} from 'vant';

Vue.config.productionTip = false

Vue.use(NavBar);
Vue.use(Toast);
Vue.use(Uploader);
Vue.use(DatetimePicker);
Vue.use(Popup);

Vue.use(Collapse);
Vue.use(CollapseItem);

Vue.use(Tab);
Vue.use(Tabs);

Vue.use(Popover);
Vue.use(PullRefresh);
Vue.use(List);
Vue.use(Form);
Vue.use(Field);
Vue.use(Overlay);
Vue.use(Picker);
Vue.use(Dialog)


Vue.use(Step);
Vue.use(Steps);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
