import axios from './axios'


//获取标签 user_status:客户形态;scheme_selection:折扣方案选择;discount_scheme:电费折扣方案;salesman_name:业务员
//map_industry:行业;distributor:经销商;time_interval:用电时段; charging:几充;get_into:几近;remarks:猜你想说
export function getLabels(tagVal) {
  return axios({
    url: '/api/power/info/getLabels',
    method:'post',
    data:{
        tagVal
    }
  })
}

//保存申请数据
export function toSave() {
    let arr = arguments[0]
    return axios({
      url: '/api/power/info/save',
      method:'post',
      data:arr
    })
  }
//获取申请数据
export function getApplyInfo(assessId,userId) {
 
  return axios({
    url: '/api/power/info/applyInfo',
    method:'post',
    data:{
      assessId,
      userId
    }
  })
}
  //保存设备选择方案
export function toProgramme() {
  let arr = arguments[0]
  return axios({
    url: '/api/power/info/programme',
    method:'post',
    data:arr
  })
}
 //获取设备选择方案
 export function getProgrammeInfo(assessId,userId,programmeType) {

  return axios({
    url: '/api/power/info/option_info',
    method:'post',
    data:{
      assessId,
      userId,
      programmeType
    }
  })
}

//首页加载列表
//progress审核状态 salesmanNumber业务员标识 scheduleControl 方案,titleNumber经销商标识 ,salesman 业务员名称
export function getList(progress,scheduleControl,salesman,titleName,userId,pageNum=1,pageSize=6){
  return axios({
      url:'/api/power/info/customerList',
      method:'post',
      data:{
          progress,
          scheduleControl,
          salesman,
          titleName,
          userId,
          pageNum,
          pageSize,
          
      }
  })
}

//上传附件
export function saveEnclosure(data) {
   
 return axios({
   url: '/api/power/info/saveEnclosure',
   method:'post',
   dataType:'json',

   data:data
 })
}

//获取上传附件 	
//附件的状态类型，1电费，2，核稽单，3水费表
export function getEnclosure(assessId,type,userId) {

 return axios({
   url: '/api/power/info/enclosure',
   method:'post',
   data:{
     assessId,
     type,
     userId
   }
 })
}

//电费测算
export function infoToEnclosure(data) {

  return axios({
    url: '/api/calculation/info/saveEnclosure',
    method:'post',
    data:data
  })
 }
 //折扣方案
 export function infoScheme(assessId) {

  return axios({
    url: '/api/calculation/info/scheme',
    method:'post',
    data:{
      assessId
    }
  })
 }
//电费折扣讯息
export function getAConclusion(assessId) {

  return axios({
    url: '/api/calculation/info/conclusion',
    method:'get',
    params:{
      assessId
    }
  })
 }
 //电费折扣讯息
export function getBConclusion(assessId) {

  return axios({
    url: '/api/calculation/info/storage',
    method:'get',
    params:{
      assessId
    }
  })
 }
 //提交申请
 export function submit_apply() {
  let arr = arguments[0]
  return axios({
    url: '/api/calculation/info/submit_apply',
    method:'post',
    data:arr
  })
 }
 //测算 b方案
 export function saveEnclosurePlanB() {
    let arr = arguments[0]
    return axios({
      url: '/api/calculation/info/saveEnclosurePlanB',
      method:'post',
      data:arr
    })
 }
 //测算结论
 export function getMeasureDetail(assessId) {

  return axios({
    url: 'api/calculation/info/details',
    method:'post',
    data:{
      assessId
    }
  })
 }
 //经销商权限  获取业务员
 export function getSalesman(userId,titleNumber="") {

  return axios({
    url: '/api/calculation/getSalesman',
    method:'get',
    params:{
      userId,
      titleNumber
    }
  })
 }
 //总部权限  获取经销商
 export function getDistributor(userId,einMenu='',titleNumber='') {

  return axios({
    url: '/api/calculation/getDistributor',
    method:'get',
    params:{
      userId,
      einMenu,
      titleNumber
    }
  })
 }
 //获取栏位
 export function getInternational(einMenu) {

  return axios({
    url: '/api/calculation/get_international',
    method:'get',
    params:{
      einMenu
    }
  })
 }