<template>
  <div id="app">
 
      <router-view />
   
    
    <MainTabBar v-if="tabType"></MainTabBar>
  </div>
</template>

<script>
import MainTabBar from './components/mainTabBar/MainTabBar.vue'

export default{
  name:'App',
  data(){
    return{
      tabType:true
    }
  },
  components:{
    MainTabBar
  },
 
  watch:{
      $route(e){
        
        if( e.name == 'Login' || e.name == 'BasicMessage' || e.name == 'UploderList' || 
        e.name == 'UploderDetail' || e.name == 'UploderCheck' || e.name == 'UploderElectricityPlan'|| 
        e.name == 'DetailMeasure' || e.name=="PowerTest" || e.name=="MeasureConclusion" ||
        e.name == 'PowerMeasure' || e.name == 'PlanExplain' || e.name == 'CustomerSumit' || e.name == 'MeasureContract'
        || e.name == 'electricityBplan'
        ){
            this.tabType = false
        }else{
          this.tabType = true
        }
      }
  },
  mounted(){
    
    let e = this.$route;
    
    if( e.name == 'Login' || e.name == 'BasicMessage' || e.name == 'UploderList' || e.name == 'UploderDetail' || 
    e.name == 'UploderCheck' || e.name == 'UploderElectricityPlan' || e.name == 'DetailMeasure' || 
    e.name=="PowerTest" || e.name=="MeasureConclusion" || e.name == 'PowerMeasure' 
     || e.name == 'PlanExplain' || e.name == 'CustomerSumit' 
     || e.name == 'MeasureContract' || e.name == 'electricityBplan'){
      
            this.tabType = false
        }else{
          this.tabType = true
        }
  },
  beforeDestroy() {
      sessionStorage.clear();
  },

}
</script>

<style>
@import "./assets/css/base.css";
#app{
    position: relative;
  }
</style>
